import roles from '@/schema/userRoles.json'

export function getStorage() {
  return localStorage.getItem('tbfRemember') === 'true' ? localStorage : sessionStorage
}

export function setTokens(data, remember = null) {
  if (remember != null) {
    localStorage.setItem('tbfRemember', remember)
  }
  if (data.user?.id) {
    window.me = data.user
    // localStorage.setItem('tbfRole', data.user.role)
  }
  const storage = getStorage()
  storage.setItem(process.env.VUE_APP_TOKEN, data.accessToken)
  storage.setItem(process.env.VUE_APP_REFRESH_TOKEN, data.refreshToken)
}

export function removeTokens() {
  const storage = getStorage()
  storage.removeItem(process.env.VUE_APP_TOKEN)
  storage.removeItem(process.env.VUE_APP_REFRESH_TOKEN)
  storage.removeItem(`${process.env.VUE_APP_TOKEN}-root`)
  storage.removeItem(`${process.env.VUE_APP_REFRESH_TOKEN}-root`)
  localStorage.removeItem('tbfRemember')
  // localStorage.removeItem('tbfRole')
  delete window.me
}

export function setFakeTokens(data) {
  const storage = getStorage()
  if (!signedAsFake()) {
    storage.setItem(`${process.env.VUE_APP_TOKEN}-root`, storage.getItem(process.env.VUE_APP_TOKEN))
    storage.setItem(`${process.env.VUE_APP_REFRESH_TOKEN}-root`, storage.getItem(process.env.VUE_APP_REFRESH_TOKEN))
  }
  storage.setItem(process.env.VUE_APP_TOKEN, data.accessToken)
  storage.setItem(process.env.VUE_APP_REFRESH_TOKEN, data.refreshToken)
  // localStorage.setItem('tbfRole', data.role)
  window.me = data

}

export function removeFakeTokens() {
  const storage = getStorage()
  storage.setItem(process.env.VUE_APP_TOKEN, storage.getItem(`${process.env.VUE_APP_TOKEN}-root`))
  storage.setItem(process.env.VUE_APP_REFRESH_TOKEN, storage.getItem(`${process.env.VUE_APP_REFRESH_TOKEN}-root`))
  storage.removeItem(`${process.env.VUE_APP_TOKEN}-root`)
  storage.removeItem(`${process.env.VUE_APP_REFRESH_TOKEN}-root`)
  // localStorage.setItem('tbfRole', 'ADMIN')
}

export function signedAsFake() {
  return !!getStorage().getItem(`${process.env.VUE_APP_TOKEN}-root`)
}

export function getTokens() {
  const storage = getStorage()
  return {
    access: storage.getItem(process.env.VUE_APP_TOKEN),
    refresh: storage.getItem(process.env.VUE_APP_REFRESH_TOKEN)
  }
}

export function isJWTExpired(token) {
  return Date.now() >= getJWTExpire(token)
  // return true || token
}

export function getJWTExpire(token) {
  const [, payload] = token.split('.')
  return JSON.parse(atob(payload)).exp * 1000
}

export function isLoggedIn() {
  const storage = getStorage()
  return !!storage.getItem(process.env.VUE_APP_TOKEN)
}

export function userCan(action) {
  return action === true || roles[window.me?.role]?.includes(action)
}

export function getGQLErrorMessages(graphQLErrors) {
  // return graphQLErrors.flatMap(({ extensions }) =>
  //   Array.isArray(extensions.response.message) ?
  //     extensions.message.flatMap(({ errors }) => errors) :
  //       extensions.message
  // )

  return graphQLErrors.flatMap(({ message }) => message)
}

export function bytesToSize(bytes, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)


  return bytes.toFixed(dp) + ' ' + units[u]
}

export function sizeToBytes(string) {
  const units = ['b', 'kb', 'mb', 'gb', 'tb']

  const parts = string.toLowerCase().split(' ')

  if (parts[1]) {
    let i = units.indexOf(parts[1])
    if (i == -1) {
      i = 0
    }
    return parts[0] * Math.pow(1000, i)
  } else {
    return false
  }
}

export function capitalize(string) {
  if (!string) return

  const temp = string.toLowerCase() ?? ''
  return temp[0].toUpperCase() + temp.slice(1)
}

export function getBeatyData(data, field, settings) {
  let result = ''
  let temp = null

  if (!data) {
    return data
  }

  if (field.key == 'keywords') {
    return data.map((d) => `<span data-k="${d}">${d}</span>`).join(', ')
  }

  switch (field.type) {
    case 'object':
    case 'list':
      temp = []

      // Show user configured fields
      field.fields.forEach((f) => {
        if (settings?.complexFields[field.key]?.includes(f.key)) {
          const val = getBeatyData(data[f.key], f)
          if (val) {
            temp.push(val)
          }
        }
      })

      // Show any non-empty fields instead
      if (!temp.length) {
        field.fields.forEach((f) => {
          const val = getBeatyData(data[f.key], f)
          if (val) {
            temp.push(val)
          }
        })
      }

      result = temp.join(field.delimiter || ', ')
      break
    case 'date':
      result = (new Date(data)).toLocaleDateString('sv-SV')
      break
    case 'array':
      result = data.length ? data?.map((d) => d?.value || d).join(field?.delimiter || ', ') : ''
      break
    case 'select':
      result = field.multiple ? data.map((d) => field.options[d]).join(field?.delimiter || ', ') : field.options[data]
      break
    default:
      result = data
      break
  }

  switch (field.modifier) {
    case 'size':
      result = bytesToSize(result)
      break
    case 'capitalize':
      result = capitalize(result)
      break
  }

  return result || field.placeholder
}

export function prepareInput(e, item) {
  let data = { id: e.id }

  switch (e.action) {
    case 'list': 
      data[e.key] = clone(item[e.key])
      data[e.key][e.index] = e.value
      break
    case 'add': 
      data[e.key] = clone(item[e.key])
      data[e.key].push(e.value)
      break
    case 'remove':
      data[e.key] = clone(item[e.key])
      data[e.key].splice(e.index, 1)
      break
    case 'object':
      data[e.key] = clone(item[e.key])
      data[e.key][e.subkey] = e.value
      break
    case 'simple':
      data[e.key] = e.value
      break
  }

  return data
}

export function prepareMultipleInput(e, item = false) {
  let data = item ? { id: e.id } : {}

  switch (e.action) {
    case 'list': 
      if (item) {
        data[e.key] = { _index: e.index, ...e.value }
      } else {
        data[e.key] = { _index: e.index }
        data[e.key][e.subkey] = e.value  
      }
      break
    case 'add':
      if (item) {
        data[e.key] = { _index: item[e.key].length, ...e.value }
      } else {
        data[e.key] = { _index: e.index, ...e.value }
      }
      break
    case 'remove':
      data[e.key] = { _index: e.index, _delete: true }
      break
    case 'object':
      if (item) {
        data[e.key] = e.value
      } else {
        data[e.key] = {}
        data[e.key][e.subkey] = e.value  
      }
      break
    case 'simple':
      data[e.key] = e.value
      break
  }
  
  return data
}

export function dummyFromSchema(schema) {
  return Object.fromEntries(
    Object.values(schema)
      .filter((f) => f.edit)
      .map((f) => {
        let val
        if (f.type == 'array' || f.type == 'list' || f.type == 'select' && f.multiple) {
          val = []
        } else {
          val = f.default
        }
        return [f.key, val]
      })
  )
}

export function clone(data) {
  return data ? JSON.parse(JSON.stringify(data)) : null
}

export function replace(string, params, data) {
  let result = string
  params.forEach((p) => result = result.replace(`:${p}`, data[p]))
  return result
}

export function exp(value, range) {
  const base = Math.E + range
  return (Math.pow(base, value) - 1) / (Math.pow(base, 1) - 1)
}

export function unexp(value, range) {
  return Math.log(value * (range + Math.E) + 1) / Math.log(Math.E + range)
}

export function selectHandler({ id, all }, store) {
  if (window.pressedKey == 'Shift') {
    let min = -1
    let current = 0
    let max = 0

    store.items.forEach((a, i) => {
      if (a.id == id) {
        current = i
      }

      if (all.includes(a.id)) {
        if (min === -1) {
          min = i
        }
        if (i > max) {
          max = i
        }
      }
    })
    if (current < max && current > min) {
      max = current
    }
    store.selected = []
    for (let i = min; i <= max; ++i) {
      store.selected.push(store.items[i].id)
    }
  } else if (window.pressedKey == 'Meta' || !all.length) {
    store.selected = all
  } else {
    store.selected = [id]
  }
}

export function getPopupPosition(el, x, y) {
  const bcr = el.getBoundingClientRect()

  const style = {}
  if (x > window.innerWidth * 0.66) {
    style.right = window.innerWidth - bcr.left + 'px'
  } else {
    style.left = bcr.left + 'px'
  }

  if (y > window.innerHeight * .66) {
    style.bottom = window.innerHeight - bcr.top + 'px'
  } else {
    style.top = bcr.bottom + 'px'
  }

  return style
}

export function setPopupPosition(el, x, y, popup) {
  Object.assign(popup.style, getPopupPosition(el, x, y))
}