<template>
  <router-view v-if="ready"  />

  <UserForm v-if="ready"
    ref="profileModal"
    success-message="Successfully saved"
    is-me="true"
    @save="saveProfile" />

  <AssetDraggingCard />

  <Alert />
  <Prompt />
</template>

<script>
import UserForm from '@/components/Users/Form'
import AssetDraggingCard from '@/components/Assets/DraggingCard'
import Alert from '@/components/Popups/Alert'
import Prompt from '@/components/Popups/Prompt'

import { ref } from 'vue'
import { useLazyQuery, useMutation } from '@vue/apollo-composable'
import { getTokens, removeTokens, setFakeTokens, removeFakeTokens } from '@/helpers'
import GET_USER from '@/graphql/user-get.gql'
import UPDATE_ME from '@/graphql/user-update-me.gql'
import FAKE_AUTH from '@/graphql/auth-fake.gql'

export default {
  name: 'App',
  components: {
    UserForm,
    AssetDraggingCard,
    Alert,
    Prompt,
  },
  setup() {
    const ready = ref(false)
    const userQuery = useLazyQuery(GET_USER)
    userQuery.onResult(({ data }) => {
      if (data?.me) {
        ready.value = true
        window.me = data.me
      }
    })

    const { mutate: Update } = useMutation(UPDATE_ME)
    const { mutate: GetTokens } = useMutation(FAKE_AUTH)

    if (getTokens().access) {
      userQuery.load()
    } else {
      ready.value = true
    }

    return {
      ready,
      Update,
      GetTokens
    }
  },
  mounted() {
    this.$watch(() => this.$route, () => {
      if (window.justLoggedIn && window.me.otpEnforced) {
        delete window.justLoggedIn
        this.$mitt.emit('prompt', {
          message: 'Two-factor authentication is required for all users on this site to protect against unauthorized access. Please press "Enable" below to activate it and follow the instructions provided.',
          btn: 'Enable',
          color: 'black',
          callback: () => this.$refs.profileModal.open(window.me, true)
        })
      }
    })
    this.$mitt.on('editProfile', this.editProfile)
    this.$mitt.on('loginAs', this.loginAs)
    this.$mitt.on('logoutAs', this.logoutAs)
    this.$mitt.on('logout', this.logout)
  },
  methods: {
    editProfile() {
      this.$refs.profileModal.open(window.me)
    },
    saveProfile(e) {
      delete e.email
      delete e.role
      delete e.id
      this.Update({ user: e })
        .then(({ data }) => {
          window.me = data.updateUser
          this.$refs.profileModal.success()
        })
        .catch((error) => console.log(error))
    },
    
    loginAs(email) {
      this.GetTokens({ email: email })
        .then(({ data }) => {
          setFakeTokens(data.masquarade)
          this.$router.go()
        })
        .catch((error) => console.log(error))
    },
    logoutAs() {
      removeFakeTokens()
      this.$router.go()
    },
    logout() {
      removeTokens()
      this.$router.go()
    },
  }
}
</script>
