import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client'
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient, CloseCode } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities"
import { getTokens, setTokens, isJWTExpired, getJWTExpire } from '@/helpers'
import REFRESH_TOKEN from '@/graphql/auth-token.gql'

const httpLink = new HttpLink({ uri: process.env.VUE_APP_API_URL })

const apolloClientAuth = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink
})

async function getRefreshedAccessTokenPromise() {
  try {
    const { data } = await apolloClientAuth.mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        refreshToken: getTokens().refresh
      }
    })
    // update new token
    setTokens(data.refreshToken, localStorage.getItem('tbfRemember'))
    return data.refreshToken.accessToken
  } catch (error) {
    // logout, show alert or something
    // signOut()

    return error
  }
}

let pendingAccessTokenPromise = null

function getAccessTokenPromise() {
  const token = getTokens().access

  if (!token) {
    return pendingAccessTokenPromise
  }

  if (!isJWTExpired(token)) {
    return new Promise(resolve => resolve(token))
  }

  if (!pendingAccessTokenPromise) {
    pendingAccessTokenPromise = getRefreshedAccessTokenPromise().finally(() => {
      pendingAccessTokenPromise = null
    })
  }

  return pendingAccessTokenPromise
}

const authLink = new ApolloLink(async(operation, forward) => {
  const token = await getAccessTokenPromise()

  operation.setContext(({ headers = {} }) => ({ headers: { ...headers, authorization: `Bearer ${token}`} }))
  return forward(operation)
})

let tokenExpiryTimeout = null;
let shouldRefreshToken = false;

// Socket API link:
const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.VUE_APP_WS_API_URL,
    options: {
      reconnect: true
    },
    connectionParams: async () => {
      if (shouldRefreshToken) {
        return ({
          accessToken: await getAccessTokenPromise()
        });
      }
      return ({
        accessToken: getTokens().access
      });
    },
    on: {
      // opened() {console.log('opened')},
      closed(event) {
        if (event.code === CloseCode.Forbidden) {
          shouldRefreshToken = true;
        }
      },
      connected(socket) {
        clearTimeout(tokenExpiryTimeout);
        // set a token expiry timeout for closing the socket
        // with an `4403: Forbidden` close event indicating
        // that the token expired. the `closed` event listner below
        // will set the token refresh flag to true
        const expireIn = getJWTExpire(getTokens().access) - Date.now();
        tokenExpiryTimeout = setTimeout(() => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.close(CloseCode.Forbidden, "Token expired");
          }
        }, Math.max(1, expireIn - 100));
      },
      // ping() {console.log('ping')},
      // pong() {console.log('pong')},
      // message(m) {console.log(m)},
      // error(e) {console.log(e)},
    }
  })
)

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  authLink.concat(httpLink)
)

export const apolloClientMain = new ApolloClient({
  cache: new InMemoryCache({addTypename: false}),
  link: link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  connectToDevTools: true
})
